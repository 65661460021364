<div class="container my-5">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/routines">Rotinas</a></li>
      <li *ngIf="formGroup.get('id').value != ''" class="breadcrumb-item active" aria-current="page">Alterar</li>
      <li *ngIf="formGroup.get('id').value == ''" class="breadcrumb-item active" aria-current="page">Adicionar Novo</li>
    </ol>
  </nav>

  <h1>Cadastro de Rotina</h1>

  <div *ngIf="isSaving" class="backdrop">
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Salvando as Informações...</span>
    </div>
  </div>

  <div id="fullPageContent" class="container my-5">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <section class="mb-4">
        <div class="row">
          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Nome da Rotina</mat-label>
              <input matInput id="name" formControlName="name" placeholder="Seu nome" />
              <mat-error *ngIf="formGroup.get('name')?.hasError('required') && formGroup.get('name')?.touched">
                O nome é obrigatório.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Cargo</mat-label>
              <mat-select formControlName="responsibilities" multiple>
                <mat-option *ngFor="let responsibility of responsibilities" [value]="responsibility.id">
                  {{ responsibility.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.get('responsibilities')?.hasError('required')">
                É obrigatório selecionar ao menos um cargo.
              </mat-error>
            </mat-form-field>

            <!-- Exibir as opções selecionadas -->
            <p class="mt-2">Selecionados: {{ getSelectedResponsibilities() }}</p>
          </div>
        </div>
      </section>

      <div class="d-grid gap-2" *ngIf="!isSaving">
        <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Salvar</button>
      </div>

      <div *ngIf="isSaving" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </form>

    <div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show mt-4 mb-4" role="alert">
      {{ successMessage }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>

    <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade show mt-4 mb-4" role="alert">
      {{ errorMessage }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>

</div>
